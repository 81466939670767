
export default {
  name: 'PagePrihlaseni',
  layout: 'auth',
  auth: 'guest',
  head: () => ({
    title: 'Přihlášení'
  }),
  mounted () {
    if (this.$auth.loggedIn) {
      if (this.$store.state.loginRedirectRoute) {
        this.$router.push(this.$store.state.loginRedirectRoute)
      } else if (this.$store.getters.loginRedirectRoute) {
        this.$router.push(this.$store.getters.loginRedirectRoute)
      } else {
        alert('store loginRedirectRoute missing')
      }
    }
  }
}
